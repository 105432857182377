import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import classes from './ProfileCascad.module.css'

export function ProfileCascad() {
  return (
    <SkeletonTheme>
      <div className={classes.title}>
        <Skeleton height={37} width='80%' />
      </div>
      <div className={classes.fieldsWrapper}>
        <div className={classes.fields}>
          <Skeleton height={20} width={230} />
          <Skeleton height={20} width={80} />
        </div>
        <div className={classes.fields}>
          <Skeleton height={20} width={230} />
          <Skeleton height={20} width={50} />
        </div>
        <div className={classes.fields}>
          <Skeleton height={20} width={230} />
          <Skeleton height={20} width={70} />
        </div>
      </div>
      <div className={classes.exit}>
        <Skeleton height={20} width={172} />
      </div>
      <div className={classes.certificate}>
        <Skeleton height={20} width={260} />
      </div>
      <div className={classes.certificateDescription}>
        <Skeleton height={20} width='80%' />
      </div>
    </SkeletonTheme>
  )
}
