import React, { useEffect, useState } from 'react'
import Certificates from '../components/Certificate/Certificates'
import classes from '@/components/css/Profile.module.css'
import Layout from '@/layouts/MainLayout'
import { LogoutModal } from '@/modals'
import { useDispatch, useSelector } from 'react-redux'
import { openLogoutModal } from '@/store/actions/modals'
import { STATUSES } from '@/constants/statuses'
import request from '@/services/request'
import { navigate } from 'gatsby'
import { ProfileCascad } from '@/cascad-screens'
import SEO from '@/components/seo'

function Profile(props) {
  const dispatch = useDispatch()
  const isAuth = useSelector(state => state.app.isAuth)
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!isAuth) {
      navigate('/')
    } else {
      getUserInfo()
    }
  }, [isAuth])

  async function getUserInfo() {
    const { data } = await request({
      url: `${process.env.GATSBY_APP_TARGET}/api/users/current`,
      method: 'get',
    })

    setUser(data)
    setLoading(false)
  }

  if (loading) return <ProfileCascad />

  return (
    <Layout>
      <SEO title='Профиль' />
      <div className={classes.section}>
        <div className={classes.container}>
          <div className={classes.title}>{user.fullName}</div>
          <div className={classes.wrapper_block}>
            <div className={classes.left_block}>
              <div className={classes.block_item}>Статус участника</div>
              {user.university && (
                <div className={classes.block_item}>
                  Название учебного заведения
                </div>
              )}
              {user.grade && (
                <div className={classes.block_item}>
                  Класс или степень студента
                </div>
              )}
            </div>
            <div className={classes.right_block}>
              <div className={classes.block_item}>{STATUSES[user.status]}</div>
              <div className={classes.block_item}>{user.university}</div>
              <div className={classes.block_item}>{user.grade}</div>
            </div>
          </div>

          <button
            onClick={() => {
              dispatch(openLogoutModal())
            }}
            className={classes.button_exit}
          >
            Выйти из аккаунта
          </button>
          <Certificates confirmed={user.certificateLogs} />
        </div>
        <LogoutModal />
      </div>
    </Layout>
  )
}

export default Profile
