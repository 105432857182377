import React from 'react'
import notHavecertificateImg from '@/images/nothavecertificat.svg'
import haveСertificateImg from '@/images/havecertificat.svg'
import classes from './Certificates.module.css'
import dayjs from 'dayjs'

function Certificates({ confirmed }) {
  return (
    <div className={classes.container}>
      {!!confirmed?.length && (
        <>
          <div className={classes.wrapper}>
            <div className={classes.title}>Сертификаты участника</div>
            <div className={classes.icon}>
              <img
                alt='иконка'
                src={confirmed ? haveСertificateImg : notHavecertificateImg}
              />
            </div>
          </div>
          <div className={classes.confirmed_desc}>
            {confirmed.map(
              certificate =>
                certificate?.path && (
                  <div className={classes.certificate__info}>
                    <p className={classes.certificate__text}>Сертификат</p>
                    <p className={classes.certificate__createTime}>
                      {dayjs(certificate.createTime).format('DD.MM.YYYY')}
                    </p>
                    <div className={classes.buttons}>
                      <a
                        className={classes.certificate__button}
                        download
                        href={`${process.env.GATSBY_APP_TARGET}/uploads/${certificate.path}`}
                      >
                        Скачать
                      </a>
                      <a
                        target='_blank'
                        className={classes.certificate__button}
                        href={`${process.env.GATSBY_APP_TARGET}/uploads/${certificate.path}`}
                      >
                        Просмотреть
                      </a>
                    </div>
                  </div>
                )
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Certificates
